<template>
    <div class="layout-1">
        <div class="content">
            <div v-for="(item,index) in items" v-observer :key="index" class="list-item">
                <h2 class="header">{{ item.title }}</h2>
                <span class="description" v-html="item.content"></span>
            </div>
        </div>
    </div>
</template>

<script>
import first from 'lodash/first'

export default {
    props: {
        data: {
            type: Object,
            default: () => {
            }
        },
        name: {
            type: String,
            default: ''
        },
    },
    computed: {
        items() {
            return first(this.data?.list?.map(item => item?.content?.tabs))
        }
    }
}
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(.15, .16, .37, 1);

.layout-1 /deep/ {
    background: white;
    padding: 89px 525px 0;

    @media only screen and (max-width: 1650px) and (min-width: 1440px) {
        padding: 89px 355px 0;
    }

    @media only screen and (max-width: 1439px) and (min-width: 1366px) {
        padding: 89px 318px 0;
    }
    @media only screen and (max-width: 1365px) and (min-width: 768px) {
        padding: 89px 47px 0;
    }

    @media only screen and (max-width: 767px) {
        padding: 55px 16px 0;
    }

    .content {
        display: flex;
        flex-direction: column;
        grid-gap: 21px;

        .list-item {
            display: flex;
            flex-direction: column;
            grid-gap: 21px;

            &.observed {
                .header, .description {
                    transform: translateY(0);
                }
            }

            .header {
                color: #E5B676;
                font-size: 34px;
                font-weight: normal;
                transform: translateY(100px);
                transition: all .6s .1s $ease-out;

                @media only screen and (max-width: 1440px) and (min-width: 1024px) {
                    transform: translateY(70px);
                }

                @media only screen and (max-width: 1023px) and (min-width: 768px) {
                    transform: translateY(50px);
                }

                @media only screen and (max-width: 767px) {
                    font-size: 21px;
                }
            }

            .description {
                color: #0A141C;
                display: block;
                font-size: 21px;
                transform: translateY(100px);
                transition: all .6s .2s $ease-out;

                @media only screen and (max-width: 1441px) {
                    font-size: 16px;
                }
                @media only screen and (max-width: 1440px) and (min-width: 1024px) {
                    transform: translateY(70px);
                }
                @media only screen and (max-width: 1023px) and (min-width: 768px) {
                    transform: translateY(50px);
                }
                @media only screen and (max-width: 767px) {
                    font-size: 16px;
                    transform: translateY(30px);
                }
            }
        }

        p, h2, span {
            text-align: left;
        }
    }
}
</style>
